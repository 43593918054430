import React, { useState, useEffect } from 'react'
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { Select } from 'src/Components/Common/Select'
import { option } from './consts'
import { useUpdateTagTypeMutation } from 'src/services/tags'

type Props = {
  isOpen: boolean
  toggle: () => void
  entity: Record<string, any>
  onUpdate: () => void
}

type SelectOption = {
  value: string
  label: string
}

const EditModal = (props: Props) => {
  const { isOpen, toggle, entity, onUpdate } = props
  const { t } = useTranslation()
  const [tag, setTag] = useState('')
  const [type, setType] = useState<SelectOption>({ value: '', label: '' })
  const [borderColor, setBorderColor] = useState('#405189')
  const [textColor, setTextColor] = useState('#405189')

  const [updateTagType] = useUpdateTagTypeMutation()

  useEffect(() => {
    if (entity) {
      setTag(entity.tag || '')
      setType({ value: entity.type || '', label: entity.type || '' })
      setBorderColor(entity.border_color || '#405189')
      setTextColor(entity.text_color || '#000000')
    }
  }, [entity])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    try {
      const result = await updateTagType({
        id: entity.id,
        data: {
          tag,
          type: type.value,
          border_color: borderColor,
          text_color: textColor
        }
      }).unwrap()

      if (result.successful) {
        onUpdate()
        toggle()
      }
    } catch (error) {
      console.error('Error updating tag:', error)
    }
  }

  return (
    <div className={'page-content'}>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>{t('Edit tag')}</ModalHeader>
        <ModalBody>
          <Form className='needs-validation' onSubmit={handleSubmit}>
            <Row>
              <Col className='col-12'>
                <FormGroup className='mb-3'>
                  <Label htmlFor='tag'>{t('Tag name')}</Label>
                  <Input
                    type='text'
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                    id='tag'
                  />
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label htmlFor='type'>{t('Type affixing')}</Label>
                  <Select
                    value={type}
                    options={option}
                    onChange={setType}
                    size='sm'
                    onFocus={undefined}
                  />
                </FormGroup>
                <FormGroup className='mb-3'>
                  <Label>{t('Colors')}</Label>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Label>Цвет рамки</Label>
                    <Input
                      type='color'
                      value={borderColor}
                      onChange={(e) => setBorderColor(e.target.value)}
                      style={{ width: '50px', padding: '5px' }}
                    />
                    <Label>Цвет текста</Label>
                    <Input
                      type='color'
                      value={textColor}
                      onChange={(e) => setTextColor(e.target.value)}
                      style={{ width: '50px', padding: '5px' }}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className='col-12 d-flex justify-content-end'>
                <Button color='primary' type='submit'>
                  {t('Update')}
                </Button>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default EditModal
